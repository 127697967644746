// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import axios from 'axios';
import _ from 'lodash';
import config from '@/config/env-constants';


export default {
	baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',	

	getMaintenances(filterBy, view, currUserId) {
		const url = `${this.baseUrl}/getMaintenances`;
		return axios.post(url, {
			view: view,
			currUserId: currUserId,
			filterBy: JSON.stringify(filterBy)
		});
	},

	addMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/addMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	},

	updateMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/updateMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	},

	completeMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/completeMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	},

	cancelMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/cancelMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	}
}
